/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Image } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"vlozka"} description={"vlozka"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="pb--60 pt--60" name={"x59hobd2kyt"}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Title className="title-box" content={"Vybíráme cylindrickou vložku"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"sluzby-2"} layout={"l30"}>
        </Column>


        <Column className="pb--60 pt--60" name={"9ou748ngnd7"} layout={"l13"}>
          
          <ColumnWrap className="column__flex --left el--2 flex--top" columns={"2"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":480}}>
              
              <Title className="title-box fs--30" content={"Měříme vložku"}>
              </Title>

              <Text className="text-box" content={"Vložka se měří od osy palce na jednu a na druhou stranu. Tím získáme poměr stran například 29+35, 35+45, 40+50 a podobně. Tento rozměr je jedním s nejdůležitějším z parametrů. Rozměr vložky se odvíjí od síly dveří. Jinou sílu dveří najdeme u vstupních dveří do bytu a jinou do rodinného domu. Jak vložku změřit můžete vidět na obrázku. V případě potřeby a usnadnění měření si u nás můžete vyzvednout měrku."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/19567/1b5077fdc0cd47f1a384816eac209017_s=660x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/19567/1b5077fdc0cd47f1a384816eac209017_s=350x_.png 350w, https://cdn.swbpg.com/t/19567/1b5077fdc0cd47f1a384816eac209017_s=660x_.png 660w, https://cdn.swbpg.com/t/19567/1b5077fdc0cd47f1a384816eac209017_s=860x_.png 860w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"9ou748ngnd7"} layout={"l13"}>
          
          <ColumnWrap className="column__flex --left el--2 flex--top" columns={"2"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":480}}>
              
              <Title className="title-box fs--30" content={"Třída bezpečnosti"}>
              </Title>

              <Text className="text-box" content={"Dalším z důležitých parametru je třída bezpečnosti. Ta se odvíjí do velké míry od použití vložky. Jinou třídu bezpečnosti budeme preferovat v brance, na boudu, garáž a jinou na vstup do rodinného domu nebo bytové jednotky. Pro vstup do rodinného domu nebo bytové jednotky doporučujeme třídu bezpečnosti minimálně 3. Tyto vložky jsou odolné proti metodám překonání jako je LOCKPICK, BUMPING a některé vložky můžou být odolné i proti odvrtání."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/19567/309715031b8c41ccbffd7423d8962deb_s=350x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/19567/309715031b8c41ccbffd7423d8962deb_s=350x_.jpg 350w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"9ou748ngnd7"} layout={"l13"}>
          
          <ColumnWrap className="column__flex --left el--2 flex--top" columns={"2"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":480}}>
              
              <Title className="title-box fs--30" content={"Knoflík vs. Prostupová spojka"}>
              </Title>

              <Text className="text-box" content={"Knoflík umožňuje zamykat dveře aniž by musel být použit klíč. Výhodou je že jde vždy odemknout klíčem z venku. Nedoporučuje se tento typ vložky používat v rodinných domech nebo bytech v přízemí.<br>Prostupová spojka umožňuje vsunutí klíče z obou dvou stran i v případě že je jeden z klíčů pootočen. Díky tomu je snížené riziko zabouchnutí dveří s pootočeným klíčem jelikož v takovém případě nejde z vnější strany klíč vsunout a odemknout ale je potřeba volat odborného pracovníka.<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/19567/9a23a93e806e40ec90335f8f91b6a9e8_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/19567/9a23a93e806e40ec90335f8f91b6a9e8_s=350x_.jpg 350w, https://cdn.swbpg.com/t/19567/9a23a93e806e40ec90335f8f91b6a9e8_s=660x_.jpg 660w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1pn2jyn pb--80 pt--80" name={"kontakt"} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/b2833eca24b249ada6d4b890c5ad2cf7_s=350x_.png);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/b2833eca24b249ada6d4b890c5ad2cf7_s=660x_.png);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/b2833eca24b249ada6d4b890c5ad2cf7_s=860x_.png);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/b2833eca24b249ada6d4b890c5ad2cf7_s=1400x_.png);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/b2833eca24b249ada6d4b890c5ad2cf7_s=2000x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/b2833eca24b249ada6d4b890c5ad2cf7_s=660x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/b2833eca24b249ada6d4b890c5ad2cf7_s=1400x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/b2833eca24b249ada6d4b890c5ad2cf7_s=3000x_.png);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s3" anim={"7"} animS={"3"}>
              
              <Title className="title-box fs--62" content={"<span style=\"color: var(--color-custom-2);\">Rádi vyhovíme Vašim přáním a požadavkům</span><br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}